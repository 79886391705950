import { useAuthHeader } from "react-auth-kit";

const useServerUrl = (): string[] => {
  const authHeader = useAuthHeader();  
  const currentUrl = window.location.href;
  let httpUrl = '';
  let wsUrl = '';

  if (currentUrl.includes('localhost')) {
    httpUrl = 'http://localhost:8080';
    wsUrl = `ws://localhost:8080/ws?authHeader=${authHeader()}`;
  } else if (currentUrl.includes('coinagetracker')) {
    httpUrl = 'https://server.coinagetracker.com';
    wsUrl = `wss://server.coinagetracker.com/ws?authHeader=${authHeader()}`;
  }

  return [httpUrl, wsUrl];
}

export default useServerUrl;
