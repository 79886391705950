
import { Position } from "../types/Position";
import { Price } from "../../prices/types/Price";
import { formatCurrency } from "../../../utils/utils";

interface TotalPnLProps {
    position: Position;
    price: Price;
}

const TotalPnL : React.FC<TotalPnLProps> = ({ position, price }) => {

  function computePnL(position: Position, price: Price): number {
    let pnl = 0;
    if(price === undefined || position === undefined){
      return pnl;
    }
    const costBasis = position.purchasePrice * position.qty;
    const currentValue = price.lastPrice * position.qty;
    let currentPnl = 0
    if(position.currentState.toUpperCase() === 'LONG'){
      currentPnl = currentValue - costBasis;
      pnl = (position.currentCapital + currentValue) - position.initialCapital;
    }else if(position.currentState.toUpperCase() === 'SHORT') {
      currentPnl = costBasis - currentValue;
      pnl = ((position.currentCapital - costBasis) + currentPnl) - position.initialCapital;
    }else{
      pnl = position.currentCapital - position.initialCapital;
    }
  
    return pnl;
}


    return (
        <span>{formatCurrency(computePnL(position, price))}</span>
    )
}
export default TotalPnL;