import { Table } from "antd";
import './PriceData.css';

import { Price } from "./types/Price";
import { formatCurrency } from "../../utils/utils";

interface PriceDataProps {
    price: Price;
}

const PriceData: React.FC<PriceDataProps> = ({ price }) => {

    const columns = [

        {
            title: 'Ask',
            dataIndex: 'askPrice',
            key: 'askPrice',
            render: (text: number, record: Price) => formatCurrency(text),
        },
        {
            title: 'Bid',
            dataIndex: 'bidPrice',
            key: 'bidPrice',
            render: (text: number, record: Price) => formatCurrency(text),
        },
        {
            title: 'Last',
            dataIndex: 'lastPrice',
            key: 'lastPrice',
            render: (text: number, record: Price) => formatCurrency(text),
        },
        {
            title: 'Close',
            dataIndex: 'closePrice',
            key: 'closePrice',
            render: (text: number, record: Price) => formatCurrency(text),
        },
    ];


    return (
        <div className="price-data-wrapper">
            <Table rowKey={(record) => record.symbol}  dataSource={[price]} columns={columns} pagination={false} size="small" />
        </div>
    );
};

export default PriceData;