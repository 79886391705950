import React, { useEffect, useState } from 'react';

interface LogoutTimerProps {
  timeoutDuration: number;
  onLogout: () => void;
}

const LogoutTimer: React.FC<LogoutTimerProps> = ({ timeoutDuration, onLogout }) => {
  const [timer, setTimer] = useState<number | null>(null);

  const resetTimer = () => {
    console.log('LogoutTimer: resetTimer is called');
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = window.setTimeout(() => {
      onLogout();
    }, timeoutDuration);

    setTimer(newTimer);
  };

  useEffect(() => {
    console.log('LogoutTimer: useEffect');
    const events: string[] = ['mousedown', 'keydown', 'touchstart', 'mousemove'];


    const handleEvent = () => {
      resetTimer();
    };

    events.forEach((event) => {
      document.addEventListener(event, handleEvent);
    });

    resetTimer();

    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleEvent);
      });

      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timeoutDuration, onLogout]);

  return null;
};

export default LogoutTimer;
