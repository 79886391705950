import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card,  Form, Input } from 'antd';

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import { useSignOut } from "react-auth-kit";
import useServerUrl from '../../hooks/useServerUrl';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import './LoginForm.css'



const LoginForm: React.FC = () => {

  const signIn = useSignIn();

  const navigate = useNavigate();

  const signOut = useSignOut();

  const [serverUrl] = useServerUrl();
  
  const onFinish = async (values: any) => {
    try {
        const response = await axios.post( `${serverUrl}/api/v1/auth/authenticate`, values );
        const et = expireTime(response.data.token)
        signIn({
          token: response.data.token,
          expiresIn: et,
          tokenType: "Bearer",
          authState: { email: values.email },
        });
        expire(et, signOut, navigate);
        navigate("/")
      } catch (err) {
        console.log("Error: ", err);
      }    
  };

  return (
    
      <Card className='login-wrapper'>
        <h2>Login</h2> {/* Header */}
          <Form name="normal_login" className="login-form" onFinish={onFinish}>
          <Form.Item name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password"/>
          </Form.Item>       
          <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">Log in</Button>           
          </Form.Item>
          </Form>
      </Card>
      
  );
};

export default LoginForm;

function expireTime(token: string): number{
  const decodedToken: JwtPayload = jwtDecode(token);
  const expireDate = decodedToken.exp ? new Date(decodedToken.exp * 1000) : new Date(new Date().getTime() + 60 * 60 * 1000);
  
  return expireDate.getTime() - new Date().getTime(); 
}

function expire(et:number, signOut: () => boolean, navigate: (path: string) => void) {  
  const delayInMinutes = 59;
  const delayInMillis = delayInMinutes * 60000;
  setTimeout(() => {
    signOut();
    navigate("/")
  }, delayInMillis);
}
