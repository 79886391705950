import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Position } from "./types/Position";

export interface PositionState {
    positions: {[positionId: number]: Position}
}

const initialState: PositionState = {
    positions: {}
}

const positionSlice = createSlice({
    name: "positions",
    initialState,
    reducers: {
        receivedPositionsAction(state, action: PayloadAction<Position[]>) {
            action.payload.forEach(position => {
                state.positions[position.id] = position;
            });
        },
        removePositionAction(state, action: PayloadAction<number>) {
            delete state.positions[action.payload];
        },
        updatePositionAction(state, action: PayloadAction<Position>) {
            state.positions[action.payload.id] = action.payload;
        }
    }
});

//selectors 
export const selectPositions = (state: {positions: PositionState}) => state.positions.positions;

export const { receivedPositionsAction, removePositionAction, updatePositionAction } = positionSlice.actions;
export default positionSlice.reducer;