import { configureStore } from "@reduxjs/toolkit";
import positionsReducer from "../components/positions/positionSlice";
import pricesReducer from "../components/prices/priceSlice";


export const store = configureStore({
    reducer: {
        positions: positionsReducer,
        prices: pricesReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;