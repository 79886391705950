import axios from "axios";
import { Price } from "../types/Price";
import useServerUrl from "../../../hooks/useServerUrl";
import { useAuthHeader } from "react-auth-kit";


export function usePriceAPI() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const [serverUrl]= useServerUrl(); 
  const priceUrl = `${serverUrl}/api/v1/price/`;

  async function getPrices(): Promise<Price[]> {
    const response = await axios.get<Price[]>(priceUrl, {
      headers: {
        Authorization: `${authHeaderValue}`
      }
    });

    return response.data;
  }


  return {
    getPrices
  };
}
