import React from 'react';
import './Home.css'
import Positions from '../positions/Positions';
import { StompSessionProvider } from 'react-stomp-hooks';
import useServerUrl from '../../hooks/useServerUrl';
import LogoutTimer from '../logout/LogoutTimer';
import { useSignOut } from 'react-auth-kit';

const Home: React.FC = () => {
  const [, url] = useServerUrl();
  const signOut = useSignOut();
  const handleLogout = () => {
    signOut();
  };
  return (
    <StompSessionProvider url={url} debug={(str) => { console.log(str); }}>
      <div style={{ width: '100%', overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <Positions />
        <LogoutTimer timeoutDuration={600000} onLogout={handleLogout} /> 
      </div>
    </StompSessionProvider>
  );
};

export default Home;