import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Price } from "./types/Price";
import exp from "constants";
import { useAppSelector } from "../../hooks/hooks";
import staticMethods from "antd/es/message";

export interface PricesState {
    prices: {[symbol: string]: Price}
}



const initialState: PricesState = {
    prices: {}
}

const priceSlice = createSlice({
    name: "prices",
    initialState,
    reducers: {
        receivedPricesAction(state, action: PayloadAction<Price[]>) {
            action.payload.forEach(price => {
                state.prices[price.symbol] = price;
            });
        },
        removePriceAction(state, action: PayloadAction<string>) {
            delete state.prices[action.payload];
        },
        updatePriceAction(state, action: PayloadAction<Price>) {
            state.prices[action.payload.symbol] = action.payload;
        }
    }
});

//selectors
export const selectPrices = (state: {prices: PricesState}) => state.prices.prices;
export const selectPrice = (symbol: string) => (state: {prices: PricesState}) => state.prices.prices[symbol];

export const { receivedPricesAction, removePriceAction, updatePriceAction } = priceSlice.actions;
export default priceSlice.reducer;