import React from 'react';
import { Layout } from 'antd';

import { Navigate, Route, Routes } from "react-router-dom";
import {  useIsAuthenticated } from 'react-auth-kit'

import { BrowserRouter } from "react-router-dom";

import Login from "./components/login/Login";
import Home from "./components/home/Home";
import './App.css';
import LogoutButton from './components/logout/LogoutButton';
import Logo from './components/logo/Logo';


const { Header, Footer, Content } = Layout;

function App() {
   

  interface PrivateRouteProps {
    Component: React.ComponentType<any>;
  }

  const PrivateRoute: React.FC<PrivateRouteProps> = ({ Component }) => {
    const isAuthenticated = useIsAuthenticated();
    const auth = isAuthenticated();
    return auth ? <Component /> : <Navigate to="/login" />;
  };

  return (

    <Layout className='appLayout'>
      <Header className="header">
        <div className='headerWrapper'>
          <Logo />
          <LogoutButton />
        </div>
      </Header>
      <Content>

        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute Component={Home } />} />
          </Routes>
        </BrowserRouter>

      </Content>
      <Footer className='footer'>
        Coinage Tracker
      </Footer>
    </Layout>
  )
}

export default App;
