import React from 'react';
import LoginForm from './LoginForm';

const Login: React.FC = () => {
  
  return (
    <LoginForm/>
  );
};

export default Login;