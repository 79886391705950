export const formatCurrency = (value: number): string => {
    if (value !== null && value !== undefined) {
      return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
      });
    } else {
      return '';
    }
  };
  