import React, { useState, ChangeEvent } from 'react';
import { Input, InputProps } from 'antd';

const UppercaseInput: React.FC<InputProps> = ({ value, onChange, ...rest }) => {
  const [inputValue, setInputValue] = useState<string | undefined>(value ? String(value) : undefined);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const uppercaseValue = e.target.value.toUpperCase();
    setInputValue(uppercaseValue);

    if (onChange) {
      onChange(e);
    }
  };

  return <Input value={inputValue} onChange={handleInputChange} {...rest} />;
};

export default UppercaseInput;
