import { Card, Switch } from "antd";
import { Position } from "./types/Position";
import './PositionData.css';
import { usePositionAPI } from "./api/usePositionApi";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { removePositionAction, updatePositionAction } from './positionSlice';
import PriceData from "../prices/PriceData";
import PositionActionButton from "./PositionActionButton";
import CurrentPnL from "./pnl/CurrentPnL";
import TotalPnl from "./pnl/TotalPnL";
import { formatCurrency } from "../../utils/utils";
import { selectPrice } from "../prices/priceSlice";

interface PositionDataProps {
    position: Position;
    onDoubleClick: (p: Position) => void;
}

const PositionData: React.FC<PositionDataProps> = ({ position, onDoubleClick }) => {
    const {deletePosition, updatePosition, transitionTo} = usePositionAPI()
    const dispatch = useAppDispatch()
    const price = useAppSelector(selectPrice(position.symbol))

    const handleDelete = (p: Position) => {
        try {
            deletePosition(p.id).then(() => { dispatch(removePositionAction(p.id)) });
        } catch (error) {
            console.error(error);
        }
    };

  


    const toggleSwitch = (p: Position) => {
        try {
            const updatedPosition = { ...p, active: !p.active };
            updatePosition(updatedPosition).then((pos) => { dispatch(updatePositionAction(pos)); });
        } catch (error) {
            console.error(error);
        }
    };

    const displayPurchasePrice = (p: Position) => {
        if (!(p.currentState.toUpperCase()=='FLAT')) {
            return <p><span className="bold-key">Last purchased Price: </span>{formatCurrency(p.purchasePrice)} </p>
        }else{
            return <></>
        }   
    }
  
    return (
        <Card onDoubleClick={() => onDoubleClick(position)} className={`position-card ${position.currentState.toLowerCase()}`}>
            <div className="position-header">
                <h3>{position.symbol}</h3>
                <div className="switch-wrapper">
                    <Switch
                        title="Active"
                        checked={position.active}
                        onClick={() => toggleSwitch(position)}
                    />
                </div>
            </div>
            <div className="separator" />

            <div className="action-wrapper">
                <PositionActionButton position={position} price={price} className="long" onClick={()=>transitionTo(position.id, 'long')}>LONG</PositionActionButton>
                <PositionActionButton position={position} price={price} className="short" onClick={()=>transitionTo(position.id, 'short')}>SHORT</PositionActionButton>
                <PositionActionButton position={position} price={price} className="close" onClick={()=>transitionTo(position.id, 'flat')}>CLOSE</PositionActionButton>
                <PositionActionButton position={position} price={price} className="delete" onClick={()=> handleDelete(position)}>DELETE</PositionActionButton>
            </div>
            <div className="separator" />
            <p><span className="bold-key">Initial Balance: </span>{formatCurrency(position.initialCapital)}</p>
            <p><span className="bold-key">Current Balance: </span>{formatCurrency(position.currentCapital)}</p>
            <p><span className="bold-key">Quantity: </span>{position.qty ? position.qty : 0}</p>
            <p><span className="bold-key">Current PnL: </span><CurrentPnL position={position} price={price}/> </p>
            { displayPurchasePrice(position)} 
            <p><span className="bold-key">Total PnL: </span><TotalPnl position={position} price={price}/></p>
            
            {price && <PriceData price={price} />}
        </Card>
    );
};

export default PositionData;
