
import { Position } from "../types/Position";
import { Price } from "../../prices/types/Price";
import { formatCurrency } from "../../../utils/utils";


interface CurrentPnlProps {
    position: Position;
    price: Price;
}

const CurrentPnL : React.FC<CurrentPnlProps> = ({ position, price }) => {

    function computePnL(position: Position, price: Price): number {
        let pnl = 0;
        if(price === undefined || position === undefined){
            return pnl;
        }
        if(position.currentState.toUpperCase() === 'LONG' ){
            const costBasis = position.purchasePrice * position.qty;
            const currentValue = price.lastPrice * position.qty;
            pnl = currentValue - costBasis;
            
        }else if(position.currentState.toUpperCase() === 'SHORT'){
            const costBasis = position.purchasePrice * position.qty;
            const currentValue = price.lastPrice * position.qty;
            pnl = costBasis - currentValue;
        }
        return pnl;

      }
    return (
        <span>{formatCurrency(computePnL(position, price))}</span>
    )
}
export default CurrentPnL;