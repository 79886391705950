import { Button, ButtonProps } from "antd";
import { Position } from "./types/Position";
import './PositionData.css';
// import { usePositionAPI } from "./api/usePositionApi";
// import { useAppDispatch } from "../../hooks/hooks";


import { Price } from "../prices/types/Price";

interface PositionActionButtonProps extends ButtonProps {
    position: Position;
    price: Price
    className?: string;
}



const PositionActionButton: React.FC<PositionActionButtonProps> = ({ position, price, className, ...rest }) => {
 

    return (
        <Button className={`action-button-${className}`} size="small" type="primary" {...rest}/>
    );
};

export default PositionActionButton;
