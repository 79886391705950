import React from 'react';
import { Modal } from 'antd';
import PositionForm from './PositionForm';
import { Position } from './types/Position'; // assuming the Position interface is defined in a separate file

interface PositionEditorProps {
  position?: Position;
  open: boolean;
  onSubmit: (values: Position) => void;
  onCancel: () => void;
}

const PositionEditor: React.FC<PositionEditorProps> = ({ position, open, onSubmit, onCancel  }) => {

  const handleFormSubmit = (values: Position) => {
    onSubmit(values);    
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      title={position ? 'Edit Position' : 'Create Position'}
      open={open}
      onCancel={handleCancel}
      footer={null}
      >
      <PositionForm position={position} onSubmit={handleFormSubmit} onCancel={handleCancel} />
    </Modal>
  );
};

export default PositionEditor;
