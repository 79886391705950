import axios from "axios";
import { Position } from "../types/Position";
import useServerUrl from "../../../hooks/useServerUrl";
import { useAuthHeader } from "react-auth-kit";


export function usePositionAPI() {
  const authHeader = useAuthHeader();
  const authHeaderValue = authHeader();
  const [serverUrl]= useServerUrl(); 
  const positionUrl = `${serverUrl}/api/v1/position/`;

  async function getPositions(): Promise<Position[]> {
    const response = await axios.get<Position[]>(positionUrl, {
      headers: {
        Authorization: `${authHeaderValue}`
      }
    });

    return response.data;
  }

  async function deletePosition(positionId: number): Promise<void> {
    await axios.delete(`${positionUrl}${positionId}`, {
      headers: {
        Authorization: `${authHeaderValue}`
      }
    });
  }

  async function createPosition(position: Position): Promise<Position> {
    const response = await axios.post<Position>(positionUrl, position, {
      headers: {
        Authorization: `${authHeaderValue}`
      }
    });

    return response.data;
  }

  async function updatePosition(position: Position): Promise<Position> {
    const response = await axios.put<Position>(positionUrl, position, {
      headers: {
        Authorization: `${authHeaderValue}`
      }
    });

    return response.data;
  }

 
  async function transitionTo(pId: number, state: string): Promise<Position> {
    const response = await axios.post<Position>(`${positionUrl}${pId}/${state}`, null, {
      headers: {
        Authorization: `${authHeaderValue}`
      }
    });

    return response.data;
  }

  return {
    getPositions,
    deletePosition,
    createPosition,
    updatePosition,
    transitionTo,
  };
}
