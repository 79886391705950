import React from 'react';
import { Form,  InputNumber, Switch, Select, Button, Space} from 'antd';
import { Position } from './types/Position'; 
import UppercaseInput from '../misc/UppercaseInput';

interface PositionFormProps {
  position?: Position; // pass the initial values when editing a position
  onSubmit: (values: Position) => void;
  onCancel: () => void;
}

const PositionEditor: React.FC<PositionFormProps> = ({ position, onSubmit, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (values: Position) => {
    onSubmit(values);
  };


  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={position}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Form.Item name="id" label="ID" hidden>
        <InputNumber />
      </Form.Item>
      <Form.Item name="symbol" label="Symbol" rules={[{ required: true, message: 'Symbol is required' }]}>
        <UppercaseInput />
      </Form.Item>
      <Form.Item name="strategyName" label="Strategy Name" rules={[{ required: true, message: 'Strategy Name is required' }]}>
        <UppercaseInput />
      </Form.Item>
      <Form.Item name="initialCapital" label="Initial Capital" rules={[{ required: true, message: 'Initial Capital is required' }]}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="currentCapital" label="Current Capital" rules={[{ required: true, message: 'Current Capital is required' }]}>
        <InputNumber />
      </Form.Item>
      <Form.Item
        name="currentState"
        label="Current State"
        rules={[{ required: true, message: 'Current State is required' }]}
      >
        <Select>
          <Select.Option value="FLAT">FLAT</Select.Option>
          <Select.Option value="LONG">LONG</Select.Option>
          <Select.Option value="SHORT">SHORT</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="qty" label="Quantity" rules={[{ required: true, message: 'Long Quantity is required' }]}>
        <InputNumber min={0}/>
      </Form.Item>
       <Form.Item name="active" label="Active" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Space size="middle">
        <Button key="cancel" onClick={onCancel}>Cancel</Button>
        <Button key="submit" type="primary" htmlType="submit">Submit</Button>      
      </Space>
    </Form>
  );
};

export default PositionEditor;
