import React, { useEffect, useState } from 'react';

import { Position } from './types/Position';
import PositionEditor from './PositionEditor';
import { useAppSelector, useAppDispatch } from '../../hooks/hooks';
import { receivedPositionsAction, selectPositions, updatePositionAction } from './positionSlice';
import { usePositionAPI } from './api/usePositionApi';
import { useSubscription } from 'react-stomp-hooks';
import PositionData from './PositionData';

import { usePriceAPI } from "../prices/api/usePrices";
import { receivedPricesAction } from '../prices/priceSlice';
import { Button,  notification } from 'antd';
import './Positions.css';
import { NotificationPlacement } from 'antd/es/notification/interface';


const Positions: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { getPositions, updatePosition, createPosition } = usePositionAPI()
  const dispatch = useAppDispatch()

  const openNotification = (placement: NotificationPlacement, msg:string) => {
    api.info({
      message: `Position updated`,
      description: msg,
      placement,
    });
  };

  useSubscription('/topic/positions', (message) => {
    const pos = JSON.parse(message.body) as Position[];
    const tickers = pos.map((p) => p.symbol).join(', ');
    openNotification('topRight', `Position for ticker(s) [${tickers}] updated`);
    dispatch(receivedPositionsAction(pos));
})

const positions = useAppSelector(selectPositions);

useEffect(() => {
  console.log('Positions: useEffect');
  getPositions().then((positions) => {
    dispatch(receivedPositionsAction(positions));
  });
}, []);



const { getPrices } = usePriceAPI()
useEffect(() => {
  const fetchPrices = async () => {
    console.log('fetchPrices')
    try {
      const prices = await getPrices();
      dispatch(receivedPricesAction(prices));
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch prices initially
  fetchPrices();

  // Set up interval to fetch prices every 5 minutes
  const interval = setInterval(fetchPrices, 5 * 60 * 1000);

  // Clean up the interval on component unmount
  return () => {
    clearInterval(interval);
  };
}, []);

const [open, setOpen] = useState(false);
const [position, setPosition] = useState<Position | undefined>();


const handleCreate = () => {
  setPosition(undefined);
  setOpen(true);
};

const handleEdit = (p: Position) => {
  setPosition(p);
  setOpen(true);
};


const handleCancel = () => {
  setPosition(undefined);
  setOpen(false);
};

const handleSubmit = (p: Position) => {
  try {
    const f = p.id ? updatePosition : createPosition;
    f(p).then((pos) => { dispatch(updatePositionAction(pos)); });
  } catch (error) {
    console.error(error);
  } finally {
    setPosition(undefined);
    setOpen(false);
  }
};


let positionData = Object.values(positions).sort((a, b) => a.symbol.localeCompare(b.symbol)).map((pos) => (
  <PositionData key={pos.id} position={pos} onDoubleClick={handleEdit} />
))
return (
  <>
    {contextHolder}
    <div className='positions-action-wrapper'>
      <Button type="primary" onClick={handleCreate} className='positions-action-button-new' size='small'>+</Button>
    </div>
    <div className="position-container">
      {positionData}
    </div>

    {open && <PositionEditor open={open} position={position} onCancel={handleCancel} onSubmit={handleSubmit} />}
  </>
);
};

export default Positions;