import { Button } from 'antd';
import React from 'react';
import { useIsAuthenticated, useSignOut } from 'react-auth-kit';
import './LogoutButton.css';

const LogoutButton: React.FC = () => {

  const isAuthenticated = useIsAuthenticated()
  const signOut = useSignOut()

  const handleLogout = async () => {
    signOut();
  };

  return (
    isAuthenticated() ? <div className="logoutButtonContainer">
      <Button type="primary" onClick={handleLogout}>Logout</Button>
    </div>
      : <></>

  );
}

export default LogoutButton;